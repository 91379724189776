<h1 mat-dialog-title>{{ 'society.provider.add.value' | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <mat-dialog-content>

        <div class="ck-content ligne_form">
            <p [innerHTML]="'society.provider.add.description.value' | translate"></p>
        </div>

        <div class="row ligne_form">

            <div class="col_12">

                <!-- Référence -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="reference" id="reference">
                    <label for="reference">{{'provider.reference.value' | translate}}</label>
                    <mat-error *ngIf="form.get('reference').errors && (form.get('reference').dirty || form.get('reference').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('reference').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>

                <!-- Activé -->

                <div class="form-group">
                    <div class="app-toggle">
                        <mat-slide-toggle formControlName="enable">{{'provider.enable.value' | translate}}</mat-slide-toggle>
                        <mat-error *ngIf="form.get('enable').errors && (form.get('enable').dirty || form.get('enable').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('enable').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>

        </div>

    </mat-dialog-content>
    <mat-dialog-actions>

        <div class="row x_center marges">
            <div class="col_content">
                <button type="button" class="btn_cta btn_little btn_green_lines" (click)="dialogRef.close()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
            </div>
            <div class="col_content">
                <button type="submit" class="btn_cta btn_little">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
            </div>
        </div>

    </mat-dialog-actions>
</form>

