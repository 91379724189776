import {TranslationItem} from "@core/shared/models/translation";
import {Offer} from "@core/shared/models/offer";
import {icon, LatLngLiteral, Layer, marker} from "leaflet";
import {Exclude, Expose} from "class-transformer";
import {Society} from "@core/shared/models/society";

export type OfferCircuitType = 'itinerant' | 'star';

@Exclude()
export class OfferLocation {

    @Expose()
    id: number;

    @Expose()
    address: string;

    @Expose()
    zipcode: string;

    @Expose()
    city: string;

    @Expose()
    department: string;

    @Expose()
    country: string;

    @Expose()
    latitude: number;

    @Expose()
    longitude: number;

    @Expose()
    inseeCode: string;

    @Expose()
    position: number;

    @Expose()
    translations: OfferLocationTranslation[];

    @Expose()
    isMain: boolean;

    @Expose()
    providers: Society[];

    @Expose()
    offer?: Offer;

    get latLng(): LatLngLiteral {

        return {
            lat: this.latitude,
            lng: this.longitude
        };
    }

    get marker(): Layer {

        return marker(this.latLng, {
            icon: icon({
                iconSize: [ 29, 45 ],
                iconAnchor: [ 16, 25 ],
                iconUrl: 'assets/images/icons/marker-icon.svg',
                shadowUrl: null
            })
        });
    }
}

export interface OfferLocationTranslation extends TranslationItem {

    title: string;

    description: string;
}
