import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {ApiService} from "@core/shared/services/api.service";
import {Observable} from 'rxjs';
import {DataGouvLocation} from '@core/shared/models/data-gouv-location';

@Injectable({
    providedIn: 'root'
})
export class DataGouvService {

    constructor(
        private _httpClient: HttpClient,
        private _apiService: ApiService
    ) {}

    public getCommune(latitude: number, longitude: number): Observable<DataGouvLocation> {

        const params: string = 'lat=' + latitude + '&lon=' + longitude;

        const headers: HttpHeaders = new HttpHeaders()
            .set('Content-Type', 'application/json')
            .set('Accept', 'application/json');

        return this._httpClient.get<DataGouvLocation>(`${this._apiService.getApiUrl(false, true)}/dataGouv/commune?${params}`,  {
            headers: headers
        });
    }

}
