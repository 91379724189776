import {ChannelFont} from "@core/shared/models/channel/channel-font";

export const CHANNEL_FONTS: ChannelFont[] = [
    {
        id: 'font-lato',
        name: 'font Lato'
    },
    {
        id: 'font-roboto',
        name: 'font Roboto'
    },
    {
        id: 'font-source',
        name: 'font Source Sans Pro'
    },
    {
        id: 'font-nunito',
        name: 'font Nunito'
    },
    {
        id: 'font-ubuntu',
        name: 'font Ubuntu'
    },
    {
        id: 'font-raleway',
        name: 'font Raleway'
    },
    {
        id: 'font-caveat',
        name: 'font Caveat'
    },
    {
        id: 'font-amatic',
        name: 'font Amatic SC'
    },
    {
        id: 'font-poiretone',
        name: 'font Poiret One'
    },
    {
        id: 'font-opensans',
        name: 'font Open Sans'
    },
    {
        id: 'font-montserrat',
        name: 'font Montserrat'
    },
    {
        id: 'font-oswald',
        name: 'font Oswald'
    },
    {
        id: 'font-slabo',
        name: 'font Slabo'
    },
    {
        id: 'font-ptsans',
        name: 'font PT Sans'
    },
    {
        id: 'font-merriweather',
        name: 'font Merriweather'
    },
    {
        id: 'font-notosans',
        name: 'font Noto Sans'
    },
    {
        id: 'font-concertone',
        name: 'font Concert One'
    },
    {
        id: 'font-prompt',
        name: 'font Prompt'
    },
    {
        id: 'font-worksans',
        name: 'font Work Sans'
    },
    {
        id: 'font-ooohbaby',
        name: 'font Oooh Baby'
    },
    {
        id: 'font-badscript',
        name: 'font Bad Script'
    },
    {
        id: 'font-dancingscript',
        name: 'font Dancing Script'
    },
    {
        id: 'font-helvetica',
        name: 'font Helvetica'
    },
    {
        id: 'font-verdana',
        name: 'font Verdana'
    },
    {
        id: 'font-garamond',
        name: 'font Cormorant Garamond'
    },
    {
        id: 'font-franklin',
        name: 'font Libre Franklin'
    },
    {
        id: 'font-baskerville',
        name: 'font Libre Baskerville'
    },
    {
        id: 'font-didot',
        name: 'font GFS Didot'
    },
    {
        id: 'font-heebo',
        name: 'font Heebo'
    },
    {
        id: 'font-redhat',
        name: 'font Red Hat Display'
    },
    {
        id: 'font-grotesk',
        name: 'font HK Grotesk'
    },
    {
        id: 'font-abel',
        name: 'font Abel'
    },
    {
        id: 'font-adventpro-light',
        name: 'font Advent Pro Light'
    },
    {
        id: 'font-josh',
        name: 'font Jost'
    },
    {
        id: 'font-lora',
        name: 'font Lora'
    },
    {
        id: 'font-montserrat-light',
        name: 'font Montserrat Light'
    },
    {
        id: 'font-montserrat-sbold',
        name: 'font Montserrat Semi Bold'
    },
    {
        id: 'font-robotocondensed',
        name: 'font Roboto Condensed'
    },
    {
        id: 'font-robotomono',
        name: 'font Roboto Mono'
    },
    {
        id: 'font-robotoslab',
        name: 'font Roboto Slab'
    },
    {
        id: 'font-rubik',
        name: 'font Rubik'
    },
    {
        id: 'font-dosis',
        name: 'font Dosis'
    },
    {
        id: 'font-oxygen',
        name: 'font Oxygen'
    },
    {
        id: 'font-dmsans',
        name: 'font DM Sans'
    },
    {
        id: 'font-firasans',
        name: 'font Fira Sans'
    },
    {
        id: 'font-siliguri',
        name: 'font Hind Siliguri'
    },
    {
        id: 'font-inconsolata',
        name: 'font Inconsolata'
    },
    {
        id: 'font-kanit',
        name: 'font Kanit'
    },
    {
        id: 'font-karla',
        name: 'font Karla'
    },
    {
        id: 'font-manrope',
        name: 'font Manrope'
    },
    {
        id: 'font-mukta',
        name: 'font Mukta'
    },
    {
        id: 'font-mulish',
        name: 'font Mulish'
    },
    {
        id: 'font-kanit',
        name: 'font Kanit'
    },
    {
        id: 'font-nanumgothic',
        name: 'font Nanum Gothic'
    },
    {
        id: 'font-quicksand',
        name: 'font Quicksand'
    },
    {
        id: 'font-titillium',
        name: 'font Titillium Web'
    },
    {
        id: 'font-avenir-next',
        name: 'font Avenir Next'
    }
];
