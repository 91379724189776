<h1 mat-dialog-title>{{ 'society.provider.request.value' | translate }}</h1>
<form [formGroup]="form" (ngSubmit)="formService.submit()">
    <mat-dialog-content>

        <div class="ck-content ligne_form">
            <p [innerHTML]="'society.provider.request.description.value' | translate"></p>
        </div>

        <div class="row ligne_form">
            <div class="col_12">

                <!-- Email -->

                <div class="form-group">
                    <input type="text" value="" placeholder=" " class="form-control" formControlName="email" id="email">
                    <label for="email">{{ 'reference.form.email.value' | translate }}</label>
                    <mat-error
                        *ngIf="form.get('email').errors && (form.get('email').dirty || form.get('email').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('email').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                            <div *ngIf="form.get('email').errors.pattern">{{ 'form.control.error.pattern.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row ligne_form">
            <div class="col_12">
                <div class="form-group">
                    <div class="checkbox">
                        <input type="checkbox" id="withEmailCopy" name="withEmailCopy" [(ngModel)]="withEmailCopy" [ngModelOptions]="{standalone: true}" (ngModelChange)="form.get('emailCopy').patchValue(null)">
                        <label for="withEmailCopy">{{ 'reference.form.email.copy.receive.value' | translate }}</label>
                    </div>
                </div>
            </div>
        </div>

        <ng-container *ngIf="withEmailCopy">
            <div class="row ligne_form">
                <div class="col_12">

                    <!-- Email de copie -->

                    <div class="form-group">
                        <input type="text" value="" placeholder=" " class="form-control" formControlName="emailCopy" id="emailCopy">
                        <label for="emailCopy">{{ 'reference.form.email.copy.value' | translate }}</label>
                        <mat-error *ngIf="form.get('emailCopy').errors && (form.get('emailCopy').dirty || form.get('emailCopy').touched)">
                            <div class="invalid-feedback">
                                <div *ngIf="form.get('emailCopy').errors.isRequired">{{ 'form.control.error.required.value' | translate }}</div>
                                <div *ngIf="form.get('emailCopy').errors.isPatternInvalid">{{ 'form.control.error.pattern.value' | translate }}</div>
                            </div>
                        </mat-error>
                    </div>
                </div>
            </div>
        </ng-container>

        <div class="row ligne_form">
            <div class="col_12">

                <!-- Langue -->

                <div class="form-group">
                    <select class="form-control" id="locale" formControlName="locale">
                        <option value=""></option>
                        <option *ngFor="let locale of locales$ | async" [value]="locale.id">{{ locale.label | translate }}</option>
                    </select>
                    <label for="locale">{{ 'locale.value' | translate }}</label>
                    <mat-error
                        *ngIf="form.get('locale').errors && (form.get('locale').dirty || form.get('locale').touched)">
                        <div class="invalid-feedback">
                            <div *ngIf="form.get('locale').errors.required">{{ 'form.control.error.required.value' | translate }}</div>
                        </div>
                    </mat-error>
                </div>
            </div>
        </div>

        <div class="row ligne_form">
            <div class="col_12">

                <!-- Commentaire -->

                <div class="form-group">
                    <textarea class="form-control" formControlName="comment" id="comment" name="comment"></textarea>
                    <label for="comment">{{ 'reference.form.comment.value' | translate }}</label>
                </div>
            </div>
        </div>

    </mat-dialog-content>
    <mat-dialog-actions>

        <div class="row x_center marges">
            <div class="col_content">
                <button type="button" class="btn_cta btn_little btn_green_lines" (click)="dialogRef.close()">{{ 'confirmDialog.cancel.action.value' | translate }}</button>
            </div>
            <div class="col_content">
                <button type="submit" class="btn_cta btn_little">{{ 'confirmDialog.confirm.action.value' | translate }}</button>
            </div>
        </div>

    </mat-dialog-actions>
</form>

