import {AbstractControl} from "@angular/forms";

/**
 * Modèle pour une localisation
 */
export interface Location {

    /**
     * Nom entier
     *
     * Example : 13 Rue gougeard 72000 Le Mans
     */
    label: string;

    /**
     * Numéro de rue
     *
     * Example : 13
     */
    housenumber?: string;

    /**
     * Adresse
     *
     * Example : Rue gougeard
     */
    street?: string;

    /**
     * Code postal
     *
     * Example : 72000
     */
    postcode?: string;

    /**
     * Ville
     *
     * Example : Le Mans
     */
    city?: string;

    /**
     * Département
     *
     * Example : Sarthe
     */
    department?: string;

    /**
     * Région
     *
     * Example : Pays de la Loire
     */
    region?: string;

    /**
     * Pays au format ISO
     *
     * Example : FR
     */
    countryISO?: string;

    /**
     * Latitude
     *
     * Example : 48.00502
     */
    latitude?: string;

    /**
     * Longitude
     *
     * Example : 0.202093
     */
    longitude?: string;

    /**
     * Code Insee
     *
     * Example :72181
     */
    inseeCode?: string;
}

/**
 * Modèle pour un champ de localisation
 */
export interface LocationField {

    /**
     * Type de champ
     */
    type: LocationFieldType;

    /**
     * Référence du champ de formulaire
     *
     * Example : this.form.get('street')
     */
    reference: AbstractControl|any;
}

/**
 * Liste des types de champ de localisation
 */
export enum LocationFieldType {
    Housenumber = "HOUSENUMBER",
    Street = "STREET",
    City = "CITY",
    Department = "DEPARTMENT",
    Region = "REGION",
    CountryISO = "COUNTRY",
    Postcode = "POSTCODE",
    Latitude = "LATITUDE",
    Longitude = "LONGITUDE",
    InseeCode = "INSEECODE"
}
